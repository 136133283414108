











  import Vue from 'vue'
  import { Component, Prop } from 'vue-property-decorator'
  import { CMS } from '@one/types'

  @Component
  export default class OneCmsStaticBanners extends Vue {
    name: string = 'OneCmsStaticBanners.vue'

    @Prop({
      required: true,
      type: Array,
    })
    readonly banners!: CMS.Banner
  }
